@tailwind base;
@tailwind components;
@tailwind utilities;

.ActiveLink{
  @apply text-[#b49132] hover:!text-[#b49132] font-[600]  !underline-offset-[6px] cursor-pointer !underline !decoration-2 md:text-[16px] lg:text-[14px] text-[12px]
}

/* .InActiveLink{
  @apply text-[#9d9d9d] font-[600] text-[16px] hover:underline hover:underline-offset-[6px] decoration-2 decoration-[#b49132]
} */
.ActiveLinkSmallScreen{
  @apply text-[#b49132] font-[600]  font-serif text-[16px]
}

.InActiveLink{
  @apply text-[#9d9d9d] lg:font-[400]  font-[600]  lg:text-[14px] md:text-[14px] hover:underline hover:underline-offset-[6px] decoration-2 decoration-[#b49132] cursor-pointer
}
.flex_center{
  @apply flex justify-center items-center
}