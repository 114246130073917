/* .underline-small{
    color:#b49132;
    display:inline-block;
    position:relative;
    
  }
  
  .underline-small::after{

    content:'';
    height:2px;
    width:75%;
    background:#b49132;
    position:absolute;
    left:calc(50% - 35%);
    bottom:-5px;
  
  } */
 
  .ant-dropdown .ant-dropdown-menu{
    background-color: rgb(16, 1, 51) !important;
    position:sticky;

  }
  
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content >a{
    /* border: 2px solid white; */
    color: white;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;
  }

  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content >a:hover{
    /* border: 2px solid white; */
    color: grey;
    
  }
  .ant-dropdown-menu .ant-dropdown-menu-sub {
    /* border: 2px solid red !important; */
    background-color: rgb(16, 1, 51) !important;
    margin-top: -2% !important;
  }
  
 .ant-collapse-content-box{
  /* border: 2px solid red !important; */
  padding: 5px 5px !important;
  color: #9d9d9d;
  text-transform: capitalize;
  background: rgb(234, 234, 234) !important; 

 }
.ant-collapse-header{
  padding: 10px 0px !important;
  font-size: 18px;
}
 .ant-collapse-header-text{
  color: #9d9d9d;
 }