.ant-table-tbody  {
    background-color: #1F2134;
    color: white;
  }

  *.ant-table-thead .ant-table-cell {
    background-color: #1F2134 !important;
    color: white !important;
    }

    .ant-table-cell.ant-table-cell-row-hover {
      background-color: #1a1c2b !important;
      color: whitesmoke !important;
    }
    .ant-picker input {
      color: darkgrey !important;
   }
   .ant-picker input::placeholder {
    color: darkgrey !important ;
 }
   .ant-picker svg {
    color: darkgrey !important;
 }